﻿///////////////////////////////////////////////////////////////////////////////
//
//  Fonts
//
///////////////////////////////////////////////////////////////////////////////

$font-size-base: 16px;

$base-line-height: 1.5;

//Main Font Univers
$font-family-open-sans:  'Open Sans', sans-serif;

//Body Font
$font-family-lora: 'Lora', serif;


///////////////////////////////////////////////////////////////////////////////
//
//  File Paths
//
///////////////////////////////////////////////////////////////////////////////

$file-path-images: './../images/';
$file-path-fonts: '/../../../../fonts/';



///////////////////////////////////////////////////////////////////////////////
//
//  Vertical Rhythm
//
///////////////////////////////////////////////////////////////////////////////
$section-spacing-large-px: 80px;
$section-spacing-larger-px: 110px;
$section-spacing-largest-px: 150px;


$section-spacing-large-mobile-px: 50px;



//refactors spacing variables will try to remove the ones on top
$element-spacing-px:  25px;
$element-spacing-small-px: 10px;


$section-spacing-px: 80px;
$section-spacing-px--custom:  48px;
$section-spacing-module-px: 110px;


//tablet & Mobiel Updates
$section-spacing-mobile-px: 40px;
$section-spacing-module-mobile-px: 80px;

//one offs
$section-spacing-reduced-more-px: 40px;
$section-spacing-reduced-px: 48px;
$section-spacing-small-px: 22px;
$result-item-spacing: 2.1875rem;


///////////////////////////////////////////////////////////////////////////////
//
//  Structure
//
///////////////////////////////////////////////////////////////////////////////

$site-max-width: 1545px;

$content-max-width: 960px;

$content-large-max-width: 1250px;

$content-max-width-small: 800px;

//FOR MEDIA QUERY BASED STYLES -- see mixins
// Extra small screen / phone
$screen-xs:       480px !default;
// For that awkward stage between phone / tablet
$screen-between:  670px !default;
// Small screen / tablet
$screen-sm:       768px !default;
// Medium screen / desktop
$screen-md:       992px !default;
// Large screen / wide desktop
$screen-lg:       1200px !default;

$site-padding: 30px;
$site-padding-px: $site-padding;

$site-padding-mobile: 16px;
$site-padding-mobile-px: $site-padding-mobile;

$site-header-height: 80px;
$site-header-height-mobile: 65px;

$angled-degree: 6deg;
$angled-degree-diversity: 8deg;


//columns widths
$column-sidebar-width: 36%;

$column-main-width: 100% - $column-sidebar-width ;



///////////////////////////////////////////////////////////////////////////////
//
//  Component/Module Variables
//
///////////////////////////////////////////////////////////////////////////////

//homepage
$newsletter-carousel-issues-width: 31%;
$newsletter-carousel-figure-width: 29%;

$newsletter-carousel-issues-width-tablet: 42%;
$newsletter-carousel-slide-width: 50%;
$newsletter-carousel-slide-figure-height: 58%;

$hero-logo-width:235px;
$hero-logo-width-tablet-mid: 200px;
$hero-logo-width-tablet: 160px;
$hero-logo-width-mobile: 140px;

//listing related
$listing-padding: 32px;
$listing-padding-mobile: 22px;


$listing-padding-search: 60px;
$listing-padding-search-mobile: 40px;

$arrow-listing-offset: 20px;


//Bio Full Snapshot
$svg-snapshot-mask-width: 464px;
$svg-snapshot-mask-height: 524px;

//thumbnail sizes
$svg-thumbnail-mask-width: 390px;
$svg-thumbnail-mask-height: 440px;

//grey wedge
$grey-wedge-width:1540px;

//icons
$arrow-chevron-size-small: 4px;

$icon-play-size: 30px;
$play-icon-dimen: 90px;
$icon-play-size--small: 20px;
$play-icon-dimen--small: 60px;


$icon-play-size-mobile: 24px;
$play-icon-dimen-mobile: 66px;

//Tiles
$entity-tile-height: 324px;
$entity-tile-height-tablet: 320px;
$entity-tile-height-mobile: 215px;

$entity-tile-height-prominent: 624px;
$entity-tile-height-prominent-tablet: 60vw;
$entity-tile-height-prominent-mobile: 320px;


$entity-tile-scale-hover: scale(1.25);

//Angled Hero Large 
$angled-polygon-height:859px; //base size


$box-shadow-polygon: 0 7px 20px 4px rgba(0,0,0,0.5);


//Grid for entity Tiles/contact Methods
$grid-spacing:  15px;


$around-corner-tile-width: 625px;

$background-pattern:  "data:image/svg+xml;charset=utf8,%3Csvg id='stripe-svg' xmlns='http://www.w3…M0,10 l10,-10 M9,11 l2,-2' stroke='%23fff' stroke-width='1' /%3E%3C/svg%3E ";
//$background-pattern:  '';

///////////////////////////////////////////////////////////////////////////////
//
//  Animations
//
///////////////////////////////////////////////////////////////////////////////

$easeInOutQuint:                    cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutCirc:                     cubic-bezier(0.785, 0.135, 0.15, 0.86);




$transition-timing: .35s;
$transition-ease: $easeInOutCirc;


$transition-default:            color $transition-timing $transition-ease,
                                border-color $transition-timing $transition-ease,
                                opacity $transition-timing $transition-ease,
                                background-color $transition-timing $transition-ease;





///////////////////////////////////////////////////////////////////////////////
//
//  ZINDEX
//
///////////////////////////////////////////////////////////////////////////////


$zindex-modal-bg:                   1000;
$zindex-modal-body:                 1005;


$zindex-site-header:               	500;
$zindex-site-header-utility:        $zindex-site-header + 10;
$zindex-site-search-panel:			$zindex-site-header + 15;

$zindex-angled-hero:				50;
$zindex-site-footer:				100;

$zindex-content-section:			20;
$zindex-scrollspy:					54;

$zindex-loading-screen:             $zindex-site-header - 10;
