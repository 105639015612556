/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: $ico-client-name;
  src: url('#{$ico-font-path}/#{$ico-client-name}.eot?#{$ico-version}');
  src: url('#{$ico-font-path}/#{$ico-client-name}.eot?#{$ico-version}#iefix') format('embedded-opentype'),
    url('#{$ico-font-path}/#{$ico-client-name}.woff?#{$ico-version}') format('woff'),
    url('#{$ico-font-path}/#{$ico-client-name}.ttf?#{$ico-version}') format('truetype'),
    url('#{$ico-font-path}/#{$ico-client-name}.svg?#{$ico-version}##{$ico-client-name}') format('svg');
  font-weight: normal;
  font-style: normal;
}
