
//$border-dark: 1px solid $color-border-dark;
$border-white: 1px solid $color-brand-white;
$border-list-heavy: 2px solid $color-border-list;
$border-list: 1px solid $color-border-list;

$grid-spacing: px-to-em($grid-spacing);

* {
    @include borderBox;

    &:before,
    &:after{
        @include borderBox;
    }
}

body {
    overflow-x: hidden;

}

figure {
	margin: 0;
}

main {
    display: block;
}

a {
	color: inherit;
    text-decoration: none;

    @include transition(color);
}

fieldset {
    margin: 0;
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}


h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

//global element, be careful when modifying
p {
    margin:0 0  px-to-em($element-spacing-px);

    &:last-child {
        margin-bottom: 0;
    }
}

button, a {
    &:focus {
        outline: $diversity-cyan solid 5px;

        & h3 {
            outline: $diversity-cyan solid 5px;
        }
    }
}

// address,
// em {
//     font-style: normal;
// }

// em {
//     //@include font-univers-condensed-oblique();
// }

// strong,
// b {
//     font-weight: normal;

//     //@include font-univers-condensed-bold();
// }
