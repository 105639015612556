@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/*==================================
=            Mixin Helpers             =
==================================*/
/*==================================
=            Load Fonts Here            =
==================================*/
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: "reedsmith";
  src: url("../fonts/reedsmith.eot?9l0j0j");
  src: url("../fonts/reedsmith.eot?9l0j0j#iefix") format("embedded-opentype"), url("../fonts/reedsmith.woff?9l0j0j") format("woff"), url("../fonts/reedsmith.ttf?9l0j0j") format("truetype"), url("../fonts/reedsmith.svg?9l0j0j#reedsmith") format("svg");
  font-weight: normal;
  font-style: normal;
}
.ico {
  font-family: "reedsmith" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-video-play:before {
  content: "\e903";
}

.ico-google-plus:before {
  content: "\ea8b";
}

.ico-facebook:before {
  content: "\ea90";
}

.ico-twitter:before {
  content: "\ea96";
}

.ico-youtube:before {
  content: "\ea9e";
}

.ico-instagram:before {
  content: "\e914";
}

.ico-vimeo:before {
  content: "\eaa0";
}

.ico-linkedin:before {
  content: "\eaca";
}

.ico-wechat:before {
  content: "\e90f";
}

.ico-print:before {
  content: "\e906";
}

.ico-vcard:before {
  content: "\e907";
}

.ico-mail:before {
  content: "\e90a";
}

.ico-mail-diversity:before {
  content: "\e910";
  color: #92338a;
}

.ico-facebook-diversity:before {
  content: "\e911";
  color: #92338a;
}

.ico-linkedin-diversity:before {
  content: "\e912";
  color: #92338a;
}

.ico-twitter-diversity:before {
  content: "\e913";
  color: #92338a;
}

.ico-external_link:before {
  content: "\e90b";
}

.ico-play:before {
  content: "\e903";
}

.ico-pause:before {
  content: "\ea1d";
}

.ico-globe-outline:before {
  content: "\e909";
}

.ico-chevron-down:before {
  content: "\e900";
}

.ico-search:before {
  content: "\e902";
}

.ico-close:before {
  content: "\e904";
}

.ico-location:before {
  content: "\e908";
}

.ico-home:before {
  content: "\e90d";
}

.ico-is-external-link:after {
  content: "\e90b";
}

.professional-card-position, .related-item-tile-featured, .dropdown-label,
.tabs-nav-mobile-toggle, .section-header-title {
  text-transform: uppercase;
}

body {
  font-size: 100%;
  font: 400 100% "Open Sans", sans-serif;
  line-height: 1.2;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #3D3C3C;
}

h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
}

h1 {
  font-size: 3.5em;
  line-height: 1.1;
}

h2 {
  font-size: 3.25em;
  line-height: 1.1;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

.is-disabled {
  cursor: auto;
  opacity: 0.5;
  user-select: none;
  color: #5B5B5B !important;
}

.is-active {
  color: #3D3C3C !important;
}

/*==========================================
=            Utility and States            =
==========================================*/
/*----------  Text Utilities  ----------*/
.text-center {
  text-align: center;
}

/*----------  Utilities  ----------*/
[v-cloak] {
  display: none;
}

.lock-scroll {
  overflow: hidden;
}

.hide-elem {
  display: none;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.util-pull-left {
  float: left;
}

.util-pull-right {
  float: right;
}

.util-mobile-only {
  display: none;
}
@media screen and (max-width: 47.4375em) {
  .util-mobile-only {
    display: block;
  }
}

@media screen and (max-width: 47.4375em) {
  .util-mobile-hidden {
    display: none;
  }
}

.is-external-link:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 0.5em;
  margin-left: 0.5em;
  margin-top: 1em;
  vertical-align: middle;
}

.is-external-link-accordion:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.5em;
  margin-left: 0.5em;
  vertical-align: middle;
}

.skip-to {
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: -1px;
}
.skip-to:focus {
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  height: auto;
  width: auto;
  clip: auto;
  margin: 0;
  display: inline-block;
  overflow: visible;
  z-index: 510;
  background-color: #fff;
  padding: 0.5em 1em;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px !important;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

figure {
  margin: 0;
}

main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

fieldset {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0 0 1.5625em;
}
p:last-child {
  margin-bottom: 0;
}

button:focus, a:focus {
  outline: #18D0E4 solid 5px;
}
button:focus h3, a:focus h3 {
  outline: #18D0E4 solid 5px;
}

/*========================================
=            Layout Variables            =
========================================*/
.page-wrapper {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.page-wrapper-careers {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10em;
}
@media screen and (max-width: 56.1875em) {
  .page-wrapper-careers {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.page-wrapper--indented {
  padding-left: 10em;
  margin-bottom: 6.875em;
}

.page-wrapper--indented-nomargin {
  padding-left: 10em;
}

.content-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .content-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.layout-column-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.layout-column-main {
  float: left;
  width: 64%;
  padding-right: 8%;
}

.layout-column-sidebar {
  width: 36%;
  float: right;
}

.layout-module {
  margin-bottom: 6.875em;
}

.layout-column-wrapper--nomargin .layout-module {
  margin-bottom: 0;
}

@media screen and (max-width: 67.125em) {
  .page-wrapper--indented, .page-wrapper--indented-nomargin {
    padding-left: 1.875em;
  }
}
@media screen and (max-width: 56.1875em) {
  .layout-module {
    margin-bottom: 5em;
  }
  .layout-column-main {
    padding-right: 5%;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-wrapper--indented, .page-wrapper--indented-nomargin {
    margin-bottom: 0;
    padding-left: 1em;
  }
  .layout-column-main,
  .layout-column-sidebar {
    float: none;
    width: auto;
  }
  .layout-column-main {
    padding-right: 0;
  }
}
.rte {
  font-family: "Lora", serif;
  font-size: 1.25em;
  line-height: 1.5;
}
@media screen and (max-width: 56.1875em) {
  .rte {
    font-size: 0.9375em;
  }
}
.rte strong {
  font-family: "Lora", serif;
  font-weight: bold;
}
.rte em {
  font-style: italic;
}
.rte ul, .rte ol, .rte p,
.rte img,
.rte address {
  margin-bottom: 1.25em;
}
.rte ul:last-child, .rte ol:last-child, .rte p:last-child,
.rte img:last-child,
.rte address:last-child {
  margin-bottom: 0;
}
.rte img {
  max-width: 100%;
  height: auto;
}
.rte ul, .rte ol {
  padding-left: 1.15em;
}
.rte li {
  margin-bottom: 1.25em;
}
.rte li:last-child {
  margin-bottom: 0;
}
.rte ul {
  list-style: none;
}
.rte ul li {
  position: relative;
}
.rte ul li:before {
  content: "•";
  color: #CC092F;
  line-height: 1.5;
  margin-left: -1em;
  position: absolute;
}
.rte ul ul li:before {
  color: #5B5B5B;
}
.rte ol {
  list-style-type: decimal;
}
.rte h2:first-child {
  margin-top: 0;
}
.rte blockquote,
.rte .blockquote {
  position: relative;
  line-height: 1.4;
  font-weight: 700;
  text-align: center;
  font-size: 1.4em;
  padding: 1.25em 0;
  margin: 2.8571428571em 0.7857142857em;
}
.rte blockquote:before, .rte blockquote:after,
.rte .blockquote:before,
.rte .blockquote:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 3.2142857143em;
  border-top: 3px solid #CC092F;
}
.rte blockquote:before,
.rte .blockquote:before {
  bottom: 100%;
}
.rte blockquote:after,
.rte .blockquote:after {
  top: 100%;
}
.rte blockquote:first-child,
.rte .blockquote:first-child {
  margin-top: 0;
}
.rte blockquote:last-child,
.rte .blockquote:last-child {
  margin-bottom: 0;
}
.rte iframe,
.rte embed,
.rte object {
  max-width: 100% !important;
}
.rte a {
  color: #CC092F;
  background-image: linear-gradient(to bottom, #5B5B5B 0%, #5B5B5B 100%);
  background-repeat: no-repeat;
  background-position: 0 1.15em;
  background-size: 0 auto;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.rte a:hover {
  background-size: 100% auto;
  color: #5B5B5B;
}
.rte a.btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  font-size: 1rem;
  font-family: "Open Sans", sans-serif !important;
}
.rte a.btn-ghost:hover {
  color: #fff;
}
.rte a.btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.rte a.btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.rte a.btn-ghost:hover {
  color: #fff;
}
.rte--diversity {
  font-family: inherit;
}
.rte--diversity--center {
  text-align: center;
}
.rte--normal-fonts {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .rte a {
    background-position: 0 1.2em;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rte a {
    background-image: none;
    display: inline;
    position: relative;
  }
  .rte a:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0%;
    bottom: -1px;
    left: 0;
    height: 1px;
    background-color: #5B5B5B;
    transition: 0.35s;
  }
  .rte a:hover:before {
    width: 100%;
  }
}
.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 35em;
}
.video-embed iframe,
.video-embed embed,
.video-embed object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.rte-title {
  font-weight: 300;
  font-size: 2em;
  margin-bottom: 0.5em;
}

.rte--lead {
  font-family: "Lora", serif;
  font-size: 1.5em;
  line-height: 1.5;
}
@media screen and (max-width: 56.1875em) {
  .rte--lead {
    font-size: 1.25em;
  }
}

.site-footer-menu, .search-landing-filters,
.searched-criteria-clear, .newsletter-carousel-issues, .location-header-map-control, .areas-focus .explore-more-cta, .related-professionals-team-view, .tabs-nav, .related-experience-dropdown .dropdown-menu,
.related-experience-dropdown .dropdown-helper-label, .nav,
.siteheader__toolbar,
.site-header-utility,
.angled-hero-firm-identity,
.site-nav,
.global-search,
.globalsearch,
.sitelogo--small, .what-others-say,
.social-block,
.recommended-content,
.related-areas,
.impactful-cta, #usercentrics-button, .angled-polygon,
.social-links,
.scrollspy-nav,
.read-more-toggle,
.btn-text-load-more,
.share-tools,
.breadcrumbs, .no-print {
  display: none !important;
}

.read-more-full {
  display: block !important;
}

* {
  font-family: serif !important;
}

body {
  font-size: 10pt;
}

.grecaptcha-badge {
  visibility: hidden;
}

button,
.btn {
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;
  font-size: inherit;
}

img {
  max-width: 100%;
}

.page-wrapper,
.content-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.angled-hero {
  margin-bottom: 3.125em;
}

.site-header,
.siteheader {
  margin-bottom: 3.125em;
}

.site-logo {
  width: 15.625em;
  display: inline-block;
}

.site-logo-img {
  display: block;
  width: 100%;
}

.breadcrumbs {
  padding-top: 1.375em;
}

.breadcrumbs-item {
  display: inline;
}
.breadcrumbs-item:after {
  content: "/";
  display: inline-block;
  padding-left: 0.5em;
  margin-right: 0.5em;
}
.breadcrumbs-item:last-child:after {
  content: none;
}

.breadcrumbs-title {
  font-size: 1.5em;
}

.page-header-title {
  font-weight: 800;
  font-size: 2.5em;
}

.page-header-subtitle {
  font-size: 1.5em;
  margin-top: 0.625em;
}

.section-header-title {
  font-weight: 800;
  font-size: 1.75em;
}

.lawyerHeader {
  font-weight: 800;
  font-size: 1.4em;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  margin-top: 1.875em;
}

.dropdown-label,
.tabs-nav-mobile-toggle {
  font-weight: 700;
  font-size: 1.125em;
}

.section-header,
.section-header-minimal {
  margin-bottom: 1.5625em;
}

.section-header-minimal-title {
  font-size: 22px;
}

.layout-module,
.layout-column-sidebar,
.section-module {
  margin-bottom: 3.75em;
}

.layout-column-sidebar {
  float: none;
  width: 100%;
}

.read-more-intro {
  margin-bottom: 1.25em;
}

.related-experience-intro {
  margin-bottom: 1.5625em;
}

.listing-experience {
  margin-top: 0.625em;
}

.listing-experience-item {
  margin-bottom: 0.625em;
}

.perspectives-news-events-intro {
  margin-bottom: 1.5625em;
}

.related-entity-panel {
  margin-top: 1.375em;
}

.related-item-tile {
  font-family: "Lora", serif;
  margin-bottom: 1.5625em;
}

.related-item-tile-heading {
  font-weight: 300;
  font-size: 1.375em;
  margin-top: 0.3125em;
}

.related-item-tile-subheading {
  font-weight: 300;
  font-size: 1em;
}

.related-item-tile-featured {
  margin-right: 0.5em;
}
.related-item-tile-featured:after {
  content: " -";
}

.accordion-node {
  margin-bottom: 1.5625em;
}

.accordion-node-title {
  font-size: 1em;
  margin-bottom: 0.625em;
}

.accordion-node-trigger {
  font-weight: 700;
  font-size: 1.375em;
}

.related-professionals-grid {
  overflow: hidden;
}

.professional-card {
  overflow: hidden;
  display: inline-block;
  width: 49%;
  vertical-align: top;
  padding-right: 1%;
}

.professional-card-thumbnail {
  width: 9.375em;
  display: inline-block;
  float: left;
}

.professional-card-info {
  margin-left: 10.625em;
}

.professional-card-position {
  font-size: 16px;
  margin-bottom: 0.625em;
}

.professional-card-link {
  font-weight: 800;
  font-size: 1.875em;
}

.flexible-panel-overview {
  overflow: hidden;
}

.flexible-panel-overview-highlight {
  float: right;
  width: 30%;
  margin-left: 2%;
}

.flexible-panel-overview .buttons {
  display: none;
}

.professional-header-content {
  overflow: hidden;
}

.professional-header-figure {
  float: left;
  width: 18.75em;
}
.professional-header-figure + .professional-header-info {
  margin-left: 20.3125em;
}

.professional-header-main {
  margin-bottom: 1.5625em;
}

.professional-header-name {
  font-weight: 800;
  font-size: 2.5em;
  line-height: 1.1;
}

.professional-header-position {
  font-size: 1.5em;
}

.professional-pronouns-position {
  font-size: 1em;
}

.professional-header-email {
  font-weight: 700;
  font-size: 1.375em;
  display: inline-block;
  margin-bottom: 0.625em;
}

.professional-header-offices {
  overflow: hidden;
}

.professional-header-office-item {
  float: left;
  font-size: 1.375em;
  max-width: 12.7272727273em;
  padding-right: 0.75em;
}

.professional-header-office {
  font-weight: 700;
  display: inline-block;
  line-height: 1.4;
}

.listing-capabilities-option,
.practices-item {
  margin-bottom: 0.625em;
}

.listing-capabilities-link,
.practices-item--main {
  font-size: 16px;
}

.credentials-intro {
  margin-bottom: 1.5625em;
}

.areas-focus-intro {
  margin-bottom: 1.5625em;
}

.additional-solutions {
  margin-top: 1.5625em;
}

.accordion-angled-node {
  margin-bottom: 1.5625em;
}

.accordion-angled-header {
  margin-bottom: 0.625em;
}

.accordion-angled-title-label {
  font-weight: 700;
  font-size: 1.375em;
}

.article-header-meta {
  font-size: 18px;
  margin-bottom: 0.625em;
}

.article-header-date + .article-header-source:before {
  content: "|";
  display: inline-block;
  margin-left: 0.1em;
  margin-right: 0.25em;
}

.article-intro-abstract {
  margin-bottom: 1.5625em;
}

.article-intro-authors,
.content-categories {
  font-family: "Lora", serif;
  font-size: 1.25em;
  line-height: 1.5;
  margin-top: 1.25em;
}
@media screen and (max-width: 56.1875em) {
  .article-intro-authors,
  .content-categories {
    font-size: 0.9375em;
  }
}

.article-intro-author {
  font-weight: 700;
}
.article-intro-author:after {
  content: ",";
  color: #3D3C3C;
}
.article-intro-author:last-child:after {
  content: "";
}

.article-body-img-lead,
.event-card {
  margin: 3.125em 0;
}

.event-card {
  font-size: 16px;
}

.event-card-label {
  font-weight: 700;
}

.attachments-list-option {
  margin-bottom: 1.5625em;
}

.attachments-list-link {
  font-size: 16px;
}

.location-header {
  margin-bottom: 3.75em;
}

.location-header-details {
  background-image: none !important;
}

.location-header-name {
  font-weight: 800;
  font-size: 2.5em;
}

.location-header-address {
  font-size: 1.5em;
  margin-top: 0.625em;
}
.location-header-address .address-item {
  display: block;
}

.location-header-address-contact {
  font-size: 1.5em;
  margin-top: 0.625em;
}
.location-header-address-contact .phone:after {
  content: "";
  display: block;
}

.around-corner-tile {
  margin: 0 auto 3.125em;
  max-width: 40.625em;
}

.around-corner-tile-figure {
  margin-bottom: 1.5625em;
}

.scrollmagic-pin-spacer {
  position: relative !important;
  height: auto !important;
}

.hero-intro-tagline {
  font-weight: 800;
  font-size: 2.5em;
  margin-bottom: 0.625em;
}

.searched-criteria {
  margin-bottom: 1.5625em;
}

.recognized-news-award {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.recognized-news-award + .recognized-news-award {
  margin-top: 5em;
}

.recognized-news-award-thumbnail {
  width: 16.25em;
  flex-shrink: 0;
}

.recognized-news-award-info {
  flex-grow: 1;
  padding: 0 3.125em;
}

.recognized-news-award-header {
  font-weight: 300;
  font-size: 1.875em;
  line-height: 1.2;
}

.recognized-news-award-abstract {
  margin-top: 0.5em;
}

.professional-results-listing {
  margin-bottom: 3.125em;
}

.professional-result {
  padding-bottom: 3.125em;
  border-bottom: 2px solid #979797;
}
.professional-result + .professional-result {
  padding-top: 3.125em;
}

.professional-result-position {
  font-size: 1.375em;
  margin-bottom: 0.625em;
}

.professional-result-name {
  font-weight: 800;
  font-size: 1.875em;
  margin-bottom: 0.625em;
  display: inline;
  line-height: 1.1;
}

.professional-result-contact-option {
  margin: 0.625em 0;
  font-size: 1.125em;
}

.professional-result-office {
  margin: 0.625em 0;
}

.site-footer-copyright {
  font-size: 16px;
}