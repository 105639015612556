﻿$icon-chevron-down: "\e900";
$icon-chevron-up: "\e901";
$icon-search: "\e902";
$icon-video-play: "\e903";
$icon-google-plus: "\ea8b";
$icon-facebook: "\ea90";
$icon-twitter: "\ea96";
$icon-youtube: "\ea9e";
$icon-instagram: "\e914";
$icon-vimeo: "\eaa0";
$icon-linkedin: "\eaca";
$icon-wechat: "\e90f";
$icon-close: "\e904";
$icon-calendar: "\e90c";
$icon-print: "\e906";
$icon-vcard: "\e907";
$icon-location: "\e908";
$icon-globe-outline: "\e909";
$icon-external_link: "\e90b";
$icon-check-mark: "\e905";
$icon-mail: "\e90a";
$icon-home: "\e90d";
$icon-video-circle: "\e90e";
$icon-email-fuschia: "\e910";
$icon-facebook-fuschia: "\e911";
$icon-linkedin-fuschia: "\e912";
$icon-twitter-fuschia: "\e913";
$icon-pause2: "\ea1d";

.#{$ico-css-prefix}-video-play:before {
    content:"\e903";
}
.#{$ico-css-prefix}-google-plus:before {
    content: $icon-google-plus;
}
.#{$ico-css-prefix}-facebook:before {
    content: $icon-facebook;
}
.#{$ico-css-prefix}-twitter:before {
    content: $icon-twitter;
}
.#{$ico-css-prefix}-youtube:before {
    content: $icon-youtube;
}
.#{$ico-css-prefix}-instagram:before {
  content: $icon-instagram;
}
.#{$ico-css-prefix}-vimeo:before {
    content: $icon-vimeo;
}
.#{$ico-css-prefix}-linkedin:before {
    content: $icon-linkedin;
}
.#{$ico-css-prefix}-wechat:before {
    content: $icon-wechat;
}
.#{$ico-css-prefix}-print:before {
	content: $icon-print;
}
.#{$ico-css-prefix}-vcard:before {
	content: $icon-vcard;
}
.#{$ico-css-prefix}-mail:before {
  content: $icon-mail;
}

.#{$ico-css-prefix}-mail-diversity:before {
    content: $icon-email-fuschia;
    color: #92338a;
}

.#{$ico-css-prefix}-facebook-diversity:before {
    content: $icon-facebook-fuschia;
    color: #92338a;
}

.#{$ico-css-prefix}-linkedin-diversity:before {
    content: $icon-linkedin-fuschia;
    color: #92338a;
}

.#{$ico-css-prefix}-twitter-diversity:before {
    content: $icon-twitter-fuschia;
    color: #92338a;
}

.#{$ico-css-prefix}-external_link {
  &:before {
    content: $icon-external_link;
  }
}
.#{$ico-css-prefix}-play {
    &:before {
        content: $icon-video-play;
    }
}
.#{$ico-css-prefix}-pause {
    &:before {
        content: $icon-pause2;
    }
}
.#{$ico-css-prefix}-globe-outline {
  &:before {
    content: $icon-globe-outline;
  }
}
.#{$ico-css-prefix}-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.#{$ico-css-prefix}-search {
    &:before {
        content: $icon-search;
    }
}
.#{$ico-css-prefix}-close {
    &:before {
        content: $icon-close;
    }
}
.#{$ico-css-prefix}-location {
  &:before {
    content: $icon-location;
  }
}
.#{$ico-css-prefix}-home {
  &:before {
    content: $icon-home;
  }
}
.#{$ico-css-prefix}-is-external-link {
  &:after {
    content: $icon-external_link;
  } 
}