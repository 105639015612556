/*==========================================
=            Utility and States            =
==========================================*/
/*----------  Text Utilities  ----------*/
.text-center {
	text-align: center;
}

/*----------  Utilities  ----------*/

[v-cloak] {
    display: none;
}

.lock-scroll {
    overflow: hidden;
}

.hide-elem {
    display: none;
}

.clearfix {
	@include clearfix;
}

.util-pull-left {
	float: left;
}

.util-pull-right {
	float: right;
}

.util-mobile-only {
	display:none;

	@include screen($bp-mobile) {
		display: block;
	}
}

.util-mobile-hidden {
	@include screen($bp-mobile) {
		display: none;
	}
}


.is-external-link {
    @include ico-icon-after($icon-external_link) {
        position: absolute;
        font-size: 0.5em;
        margin-left: 0.5em;
        margin-top: 1em;
        vertical-align: middle;
    }
}

.is-external-link-accordion {
    @include ico-icon-after($icon-external_link) {
        font-size: 0.5em;
        margin-left: 0.5em;
        vertical-align: middle;
    }
}

// Only display content to screen readers. A la Bootstrap 4.
//
// See: http://a11yproject.com/posts/how-to-hide-content/
.skip-to {
    height: 1px;
    width: 1px;
    clip: rect(0px, 0px, 0px, 0px);
    overflow: hidden;
    position: absolute;
    top: 0;
    margin: -1px;

    &:focus {
        @include transition(background-color);

        height: auto;
        width: auto;
        clip: auto;
        margin: 0;
        display: inline-block;
        overflow: visible;
        z-index: $zindex-site-header + 10;
        background-color: $color-brand-white;
        padding: 0.5em 1em;
    }
}


//visually hide content but accssible to screen readers
//http://a11yproject.com/posts/how-to-hide-content/
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px !important;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
