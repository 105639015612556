// DISABLED
.is-disabled {
    cursor: auto;
    opacity: 0.5;
    user-select: none;
    color: $color-text-gray !important;
}

.is-active {
	color: $color-brand-black !important;
}