﻿// Variables
// --------------------------

$ico-font-path:        	"../fonts" !default;
$ico-font-size-base:   	16px !default;
$ico-css-prefix:       	ico !default;
$ico-version:           "9l0j0j" !default;
$ico-border-color:     	#eee !default;
$ico-inverse:          	#fff !default;
$ico-li-width:         	1 !default;
$ico-client-name: 		'reedsmith';