//  Brandon Mathis Media Queries
///////////////////////////////////////////////////////////////////////////////
//
//	http://codepen.io/imathis/pen/BDzcI
//
//  You should use the Breakpoint Aliases (below) when referencing media query
//  breakpoints with your custom CSS
//
//	Breakpoint Values
//	http://www.paintcodeapp.com/news/iphone-6-screens-demystified
///////////////////////////////////////////////////////////////////////////////

$bp-desktop-large: 1200px;
$bp-desktop: 1075px;
$bp-between-desktop: 1000px;
$bp-between-tablet: 1024px;
$bp-tablet-landscape: 1025px;
$bp-tablet: 900px;
$bp-mobile: 760px;
$bp-mobile-landscape: 630px;
$bp-mobile-portrait: 420px;

//module specific breakpoints
$bp-hero-intro-tablet: 1000px;
$bp-newsletter-carousel-tablet: 1000px;
$bp-two-column: 850px;

//angled hero
$angled-hero-desktop: 1300px;

//maxheight specific
//$bp-height-menu-desktop: 600px;

@mixin screen($device-width, $type: max) {
    @if $type == "min" {
        @media screen and (min-width: px-to-em($device-width, $font-size-base)) {
            @content;
        }
    }
    @else {
        @media screen and (max-width: px-to-em($device-width - 1, $font-size-base)) {
            @content;
        }
    }
}

@mixin screen-height($device-height, $type: max) {
    @if $type == "min" {
        @media screen and (min-height: px-to-em($device-height, $font-size-base)) {
            @content;
        }
    }
    @else {
        @media screen and (max-height: px-to-em($device-height - 1, $font-size-base)) {
            @content;
        }
    }
}
