//Brand colors
$color-brand-black: #3D3C3C;
$color-brand-white: #fff;
$color-brand-gray:  #d9d9d9;
$color-brand-red: #CC092F;
$color-brand-gold: #E5B522;

$color-gray: #f3f3f3;
$color-gray-dark: #181818;
$color-black: #222;
$color-light-red: #E68497;
$color-charcoal: #404040;

//opaque
$color-opaque-black: rgba(0,0,0, .6);
$color-opaque-black-light: rgba(0,0,0, .25);
$color-opaque-white: rgba(255,255,255, .65);

//text colors
$color-text-copy:  				$color-brand-black;
$color-text-gray: 				#5B5B5B;
$color-text-gray-light:			#6E6E6E;
$color-text-gold: 				#B58F1B;
$color-wedge-gray: 				#3C3B3B;

$color-text-white-hover: 		#9d9d9d;



//context color assignments
$color-link: $color-brand-red;
$color-link-hover: $color-text-gray;

//component colors
$color-border:#AEAEAE;
$color-border-list: #979797;

$color-entity-tile: #373737;
$color-entity-tile-dim: $color-opaque-black;
$color-entity-tile-dim-hover: rgba(0,0,0, 0.7);


//gradient start
$color-gradient-red-start: #AE0828;
$color-gradient-red-end: $color-brand-red;


//Diversity
$diversity-light-gray: #ececec;
$diversity-medium-gray: #656565;
$diversity-dark-gray: #3d3c3c;
$diversity-green: #1e9f48;
$diversity-light-green: #b7f3c6;
$diversity-lime: #A5CF4D;
$diversity-blue: #0373ac;
$diversity-cyan: #18D0E4;
$diversity-fuschia: #92338a;
$diversity-red: #e51937;
$diversity-orange: #E76D25;

//Forecast
//RS Melon 
$forecast-primary-melon: #1e9f48;
$forecast-secondary-melon: #45A565;

//RS Marine 
$forecast-primary-marine: #0373ac;
$forecast-secondary-marine: tint($forecast-primary-marine, 80%);

//RS Plum 
$forecast-primary-plum: #92338a;
$forecast-secondary-plum: tint($forecast-primary-plum, 80%);

//RS Red 
$forecast-primary-red: #cd122d;
$forecast-secondary-red: tint($forecast-primary-red, 80%);

//RS Olive
$forecast-primary-olive: #558033;
$forecast-secondary-olive: #64963c;

//RS Brand Red
$forecast-primary-brand-red: #CC092F;
$forecast-secondary-brand-red: tint($forecast-primary-brand-red, 80%);

//RS Royal
$forecast-primary-royal: #283584;
$forecast-secondary-royal: tint($forecast-primary-royal, 80%);

//RS Tangerine
$forecast-primary-tangerine: #E94B00;
$forecast-secondary-tangerine: tint($forecast-primary-tangerine, 80%);

//RS Dark
$forecast-primary-dark: #696969;
$forecast-secondary-dark: tint($forecast-primary-dark, 80%);

//RS Light
$forecast-primary-light: #6A6A6A;
$forecast-secondary-light: tint($forecast-primary-light, 80%);