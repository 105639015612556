//custom font-size:
$section-spacing-px: 50px;
$section-spacing: px-to-em($section-spacing-px);

$section-spacing-module-px: 60px;
$section-spacing-module: px-to-em($section-spacing-module-px);

$page-header-font: 40px;
$page-section-header: 28px;
$page-subtitle: 24px;
$page-subbody: 16px;

$font-size-h1: 40px;
$font-size-h2: 30px;
$font-size-h3: 22px;
$font-size-h4: 18px;
$font-size-h5: 16px;

//placeholders
%hide {
    display: none !important;
}

%always-show {
    display: block !important;
}
*{
	font-family: serif !important;
}
// Default
body {
	font-size: 10pt;
}

.no-print {
	@extend %hide;
}

.grecaptcha-badge {
    visibility: hidden;
}

button,
.btn {
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding:0;
    margin: 0;
    font-size: inherit;
}

img {
	max-width: 100%;
}

//hide unnecessary elements
.angled-polygon,
.social-links,
.scrollspy-nav,
.read-more-toggle,
.btn-text-load-more,
.share-tools,
.breadcrumbs {
	@extend %hide;
}

// Cookie popup

#usercentrics-button {
	@extend %hide;
}

//HIDDEN MODULES
.what-others-say,
.social-block,
.recommended-content,
.related-areas,
.impactful-cta  {
	@extend %hide;
}

//general styles 
.page-wrapper,
.content-wrapper {
	padding-left: 0;
	padding-right: 0;
}

//vertical spacing
.angled-hero {
	margin-bottom: $section-spacing;
}

// Header
.nav,
.siteheader__toolbar,
.site-header-utility,
.angled-hero-firm-identity,
.site-nav,
.global-search,
.globalsearch,
.sitelogo--small {
    @extend %hide;
}

.site-header,
.siteheader {
    margin-bottom: $section-spacing;
}

.site-logo {
	width: px-to-em(250px);
	display: inline-block;
}

.site-logo-img {
	display: block;
	width: 100%;
}

.breadcrumbs {
	padding-top: $section-spacing-small;
}

.breadcrumbs-item {
	display: inline;

	&:after {
		content: '/';
		display: inline-block;
		padding-left: 0.5em;
		margin-right: 0.5em;
	}

	&:last-child {

		&:after {
			content: none;
		}
	}
}

.breadcrumbs-title {
	font-size: px-to-em($page-subtitle);
}



//vertical spacing and page font sizes
//

.page-header-title {
	@include font-open-sans-extra-bold($page-header-font);
}

.page-header-subtitle {
	font-size: px-to-em($page-subtitle);
	margin-top: $element-spacing-small;
}


.section-header-title {
	@extend %uc;

	@include font-open-sans-extra-bold($page-section-header);
}

.lawyerHeader {
	font-weight: 800;
	font-size: px-to-em($page-section-header, $font-size-copy);
	font-family: $font-family-open-sans;
	text-transform: uppercase;
	margin-top: px-to-em(30px);
}

.dropdown-label,
.tabs-nav-mobile-toggle {
	@extend %uc;

	@include font-open-sans-bold($font-size-h4);
}

.section-header,
.section-header-minimal {
	margin-bottom: calc($section-spacing / 2);
}

.section-header-minimal-title { 
	font-size: $font-size-h3;
}


.layout-module,
.layout-column-sidebar,
.section-module {
	margin-bottom: $section-spacing-module;
}

.layout-column-sidebar {
	float: none;
	width: 100%;
}


//common Detail modules

//read-moe
.read-more-toggle {
	@extend %hide;
}

.read-more-full {
	@extend %always-show;
}

.read-more-intro { 
	margin-bottom: px-to-em($element-spacing-px, $font-size-copy);
}

//experience listing
.related-experience-intro {
	margin-bottom: $element-spacing;
}

.related-experience-dropdown {
	.dropdown-menu,
	.dropdown-helper-label {
		@extend %hide;
	}	
}
.listing-experience {
	margin-top: $element-spacing-small;
}
.listing-experience-item {
	margin-bottom: $element-spacing-small;
}

//tabs
.tabs-nav {
	@extend %hide;
}


//related news
.perspectives-news-events-intro {
	margin-bottom: $element-spacing;
}

.related-entity-panel {
	margin-top: $section-spacing-small;
}

.related-item-tile {
	font-family: $font-family-lora;
	margin-bottom: $element-spacing;
}

.related-item-tile-heading {
	@include font-open-sans-light($font-size-h3);
	margin-top: calc($element-spacing-small/2);
}

.related-item-tile-subheading {
	@include font-open-sans-light($font-size-h5);
}

.related-item-tile-featured {
	@extend %uc;
	// font-weight: $font-weight-bold;
	margin-right: 0.5em;
	&:after {
		content: ' -';
	}
}

// accordions

.accordion-node  {
	margin-bottom: $element-spacing;	
}

.accordion-node-title  {
	 font-size: 1em;
	 margin-bottom: $element-spacing-small;
}

.accordion-node-trigger {
	@include font-open-sans-bold($font-size-h3);
}


//related professionals;
//

.related-professionals-grid {
	overflow: hidden;
	
}
.professional-card {
	overflow: hidden;
	display: inline-block;
	width: 49%;
	vertical-align: top;
	padding-right: 1%;
}

.professional-card-thumbnail {
	width: px-to-em(150px);
	display: inline-block;
	float: left;
}

.professional-card-info {
	margin-left:  px-to-em(170px);
}

.related-professionals-team-view {
	@extend %hide;
}

.professional-card-position {
	@extend %uc;

	font-size: $font-size-h5;
	margin-bottom: $element-spacing-small;
}

.professional-card-link {
	@include font-open-sans-extra-bold($font-size-h2);
}

.flexible-panel-overview {
	overflow: hidden;
}

.flexible-panel-overview-highlight {
	float: right;
	width: 30%;
	margin-left: 2%;
}


.flexible-panel-overview {
	.buttons {
		display: none;
	}
}

//Professional Detail

.professional-header-content {
	overflow: hidden;
}

.professional-header-figure {
	$figure-width: px-to-em(300px);
	float: left;
	width: $figure-width;

	& + .professional-header-info {
		margin-left: $figure-width + $element-spacing ;
	}
}


.professional-header-main {
	margin-bottom: $element-spacing;
}


.professional-header-name {
	@include font-open-sans-extra-bold($page-header-font);
	line-height: 1.1;
}


.professional-header-position {
	font-size: px-to-em($page-subtitle);
}

.professional-pronouns-position {
    font-size: px-to-em($page-subbody);
}

.professional-header-email {
	@include font-open-sans-bold($font-size-h3);
	display: inline-block;
	margin-bottom: $element-spacing-small;
}


.professional-header-offices {
	overflow: hidden;
}
.professional-header-office-item {
	float: left;
	font-size: px-to-em($font-size-h3);
	max-width: px-to-em(280px, $font-size-h3);
	padding-right: 0.75em;
}

.professional-header-office {
	font-weight: $font-weight-bold;
	display: inline-block;
	line-height: 1.4;
}

.listing-capabilities-option,
.practices-item {
	margin-bottom: $element-spacing-small;
}

.listing-capabilities-link,
.practices-item--main {
	font-size: $font-size-h5;
}


//credentials
.credentials-intro {
	margin-bottom: $element-spacing;
}


//capabilitiy detail
.areas-focus {
	.explore-more-cta {
		@extend %hide;
	}
}
.areas-focus-intro {
	margin-bottom: $element-spacing;
}

.additional-solutions {
	margin-top: $element-spacing;
}

.accordion-angled-node {
	margin-bottom: $element-spacing;	
}

.accordion-angled-header  {
	 margin-bottom: $element-spacing-small;
}

.accordion-angled-title-label {
	@include font-open-sans-bold($font-size-h3);
}



//Articles
.article-header-meta {
	font-size: $font-size-h4;
	margin-bottom: $element-spacing-small;
}

.article-header-date {
	& + .article-header-source {
		&:before {
			content: "|";
			display: inline-block;
			margin-left:0.1em;
			margin-right:0.25em;
		}
	}
}

.article-intro-abstract {
	margin-bottom: $element-spacing;
}

.article-intro-authors,
.content-categories {
	@include font-size-copy-text;

	margin-top: px-to-em($element-spacing-px, $font-size-copy);

}

.article-intro-author {
	font-weight: $font-weight-bold;


	&:after {
		content: ',';
		color: $color-text-copy;
	}

	&:last-child {
		&:after {
			content: '';	
		}
	}
}

.article-body-img-lead,
.event-card {
	margin: $section-spacing 0;
}


.event-card {
	font-size: $font-size-h5;	
}

.event-card-label {
	font-weight:$font-weight-bold;
}

.attachments-list-option {
	margin-bottom: $element-spacing;
}

.attachments-list-link {
	font-size: $font-size-h5;	
}


//LOcation
.location-header {
	margin-bottom: $section-spacing-module;
}

.location-header-details {
	background-image: none !important;
}

.location-header-name {
	@include font-open-sans-extra-bold($page-header-font);
}

.location-header-address {
	font-size: px-to-em($page-subtitle);
	margin-top: $element-spacing-small;
	.address-item {
		display:block;
	}
}

.location-header-map-control {
	@extend %hide;
}

.location-header-address-contact {
	font-size: px-to-em($page-subtitle);
	margin-top: $element-spacing-small;

	.phone {
		&:after {
			content: '';
			display: block;
		}
	}
}

.around-corner-tile {
	margin:0 auto $section-spacing;
	max-width: px-to-em(650px);
}

.around-corner-tile-figure {
	margin-bottom: $element-spacing;
}

.scrollmagic-pin-spacer {
	position: relative !important;
	height: auto !important;
}


//homepage
.hero-intro-tagline {
	@include font-open-sans-extra-bold($page-header-font);

	margin-bottom: $element-spacing-small;
}


//Newsletter Carousel
.newsletter-carousel-issues {@extend %hide;}

.newsletter-carousel-issue-title {

}

//search landing pages
.search-landing-filters,
.searched-criteria-clear {
	@extend %hide;
}

.searched-criteria {
	margin-bottom: $element-spacing;
}


//professional landing
.recognized-news-award {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;


	& + & {
		margin-top: $section-spacing-large;
	}
}

.recognized-news-award-thumbnail {
	width: px-to-em(260px);
	flex-shrink: 0;

}

.recognized-news-award-info {
	flex-grow: 1;
	padding:0 $section-spacing;
}

.recognized-news-award-header {
	@include font-open-sans-light($font-size-h2);

	line-height: 1.2;
}

.recognized-news-award-abstract {
	margin-top: px-to-em($element-spacing-small-px, $font-size-copy);
}

.professional-results-listing  {
	margin-bottom: $section-spacing;
}


.professional-result {
	padding-bottom: $section-spacing;
	border-bottom: $border-list-heavy;

	& + & {
		
		padding-top: $section-spacing;
	}
}

.professional-result-position {
	font-size: px-to-em($font-size-h3);
	margin-bottom: $element-spacing-small;
}

.professional-result-name {
	$heading-size: 48px;

	@include font-open-sans-extra-bold($font-size-h2);
	margin-bottom: $element-spacing-small;
	display: inline;
	line-height: 1.1;
}

.professional-result-contact-option {
	margin: $element-spacing-small 0;
	font-size: px-to-em($font-size-h4);
}

.professional-result-office {
	margin: $element-spacing-small 0;
}

//footer {
.site-footer-menu,
.social-links {
	@extend %hide;
}

.site-footer-copyright {
	font-size: $font-size-h5;
}
