﻿
.rte {
    $blockquote-size: 28px;

    @include font-size-copy-text();

    strong {
        font-family: $font-family-copy;
        font-weight: bold;
    }



    em {
        font-style: italic;
    }

    ul, ol, p,
    img,
    address {
        margin-bottom: px-to-em($element-spacing-px, $font-size-copy);

        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    ul, ol {
        padding-left: 1.15em;
    }

    li {
        margin-bottom: px-to-em($element-spacing-px, $font-size-copy);

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style: none;

        li {
            position: relative;

            &:before {
                content: '•';
                color: $color-brand-red;
                line-height: 1.5;
                margin-left: -1em;
                position: absolute;
            }
        }

        ul {
            li {
                &:before {
                    color: $color-text-gray;
                }
            }
        }
    }

    ol {
        list-style-type: decimal;
    }



    h2 {
        //margin-bottom: px-to-em($element-spacing-px, $font-size-h2);
        //margin-top: px-to-em($section-spacing-px, $font-size-h2);
        &:first-child {
            margin-top: 0;
        }
    }

    h3 {
        //margin-bottom: px-to-em($element-spacing-px, $font-size-h3);
    }

    blockquote,
    .blockquote {
        position: relative;
        line-height: 1.4;
        font-weight: $font-weight-bold;
        text-align: center;
        font-size: px-to-em($blockquote-size, $font-size-copy);
        padding: px-to-em($element-spacing-px, $font-size-copy) 0;
        margin: px-to-em($section-spacing-px, $blockquote-size) px-to-em($section-spacing-small-px, $blockquote-size);

        &:before,
        &:after {
            @include center(horizontal);
            content: '';
            width: px-to-em(90px, $blockquote-size);
            border-top: 3px solid $color-brand-red;
        }

        &:before {
            bottom: 100%;
        }

        &:after {
            top: 100%;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    iframe,
    embed,
    object {
        max-width: 100% !important;
    }

    a {

        $underline-color: $color-link-hover;

        color: $color-link;
        background-image: linear-gradient(to bottom, $underline-color 0%, $underline-color 100%);
        // background-image: url($file-path-images + 'line.jpg');
        background-repeat: no-repeat;
        background-position: 0 1.15em;
        background-size: 0 auto;
        transition: color $transition-timing $transition-ease, background $transition-timing $transition-ease;

        &:hover {
            background-size: 100% auto;
            color: $color-link-hover;
        }
    }

    a.btn-ghost {
        @include button-transition(); 
        font-size: rem(16px);
        font-family: $font-family-primary !important;
        &:hover {
            color: $color-brand-white;
        }
    }

    &--diversity {
        font-family: inherit;

        &--center {
            text-align: center;
        }
    }

    &--normal-fonts {
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
    }
}

//from http://browserhacks.com/ FIrefox media query hack
@media all and (min--moz-device-pixel-ratio:0) {
	.rte a{
		background-position: 0 1.2em;
	}
}

//from http://browserhacks.com/ IE EDge and up media query hack
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.rte a{
		background-image: none;
		display: inline;
		@include underline-transition($color-link-hover)
	}
}

.video-embed {
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	width: 100%;
	max-width: px-to-em(560px);

	iframe,
	embed,
	object {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100% !important;
		height: 100% !important;
	}
}

.rte-title {
	@include font-open-sans-light(32px);

	margin-bottom: .5em;
}

.rte--lead {
	@include font-size-copy-lead;
}