@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin borderBox {
    box-sizing: border-box;
}

@mixin display($display: block) {
    display: $display;

    @if ($display == inline-block and $legacy_support_for_ie) {
        *display: inline;
        zoom: 1;
    }
}

/// Helper mixin to size elements
/// @param {Number} $width - Width
/// @param {Number} $height ($width) - Height
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin transition($property: all, $timing: $transition-timing, $easing: $transition-ease) {
    transition: $property #{$timing} $easing;
}
@mixin link-transition($textColor: $color-link, $hoverColor:$color-link-hover){
	position:relative;
	transition: none;
	color: $textColor;
	display: inline-block;
	&:before{
		content: ''; 
		position: absolute;
		display: inline-block;
		width: 0%;
		bottom: -1px;
		left: 0;
		height: 1px;
		background-color: $hoverColor;
		transition: $transition-timing;
	}
	&:hover{
		color: $hoverColor;
		&:before{
			width: 100%;
		}
  }
  @include screen($bp-mobile){
    //get rid of hover states for mobile since it causes double click for iphone users.
    &:before{
      display: none;
    }
    &:hover{
      &:before{
        display: none;
      }
    }
  }
}

@mixin underline-transition ($line-color: $color-link) {
  position: relative;
  &:before{
    content: '';
    position: absolute;
    display: inline-block;
    width: 0%;
    bottom: -1px;
    left: 0;
    height: 1px;
    background-color: $line-color;
    transition: $transition-timing;
  }
  &:hover{
    &:before{
      width: 100%;
    }
  }
}
@mixin bg-cover {
  background:transparent no-repeat center center;
  background-size: cover;
}

@mixin center($direction, $position: absolute) {


  @if $position == 'relative' {
    position: relative;
  }

  @else {
    position: absolute;
  }

  @if $direction == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  }

  @else if $direction == 'horizontal' {
    left: 50%;
    transform: translateX(-50%);
  }

  @else if $direction == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin design-mark($spacing: 25px, $font-size: 16px, $border-color: $color-brand-red , $placement: after, $position: relative ) {


  @if $position == 'absolute' {
    position: absolute;
  }
  @else {
    position: relative;
  }

  @if $placement == 'before' {
    &:before {
      content: '';
      display: block;
      width: px-to-em($global-line-mark, $font-size);
      border-top: $global-line-thickness solid $border-color;
      padding-top: px-to-em($spacing, $font-size);
      margin-top: px-to-em($spacing, $font-size);
      @include transition(border-color);
    }
  }
  @else {

    &:after {
      content: '';
      display: block;
      width: px-to-em($global-line-mark, $font-size);
      border-top: $global-line-thickness solid $border-color;
      padding-top: px-to-em($spacing, $font-size);
      margin-top: px-to-em($spacing, $font-size);

      @include transition(border-color);
    }
  }
}

@mixin absolute-full {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

//Mixin for placeholder text
@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin gradient($start-color: #555, $end-color: #333, $deg: 45deg, $spread: 100%) {
  background-repeat: repeat-x;
  background-image: linear-gradient($deg, $start-color, $end-color $spread); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}

@mixin gradient-overlay($start-color: rgba(0,0,0,0.3), $end-color: rgba(0,0,0,1), $direction: bottom, $position: before) {
  &:#{$position} {
    
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to $direction, $start-color, $end-color);

    @content;
  }
}


@mixin aspect-ratio($width: 100px, $height: 100px, $max-width: 100%) {
    width: $max-width;
    padding-top: ($height / $width) * 100%;
}

@mixin svg-ratio-preservation() {
  $svg-ratio: 1280/800;
  $svgWidth: 1280px;
  $svgHeight: 800px;
  $width-value: 100%;

  position: absolute;
  opacity: .5;


    /**
     * Padding hack for responsive control
     * http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
     *
     */

    height: 0;             /* collapse the container's height */
    width: $width-value;    /* specify any width you want (a percentage value, basically) */
    /* apply a padding using the following formula */
    /* this formula makes sure the aspect ratio of the container equals that of the svg graphic */
    padding-top: $svg-ratio * $width-value;

    svg {
      position: absolute;
      left: 0;
      width: 100%;
      transform: translateY(-50%) translateZ(0);
    }
}

@mixin columns($columns: 2, $spacing: $default-column-spacing) {
  columns: $columns auto;
  column-gap: $spacing;
}

@mixin column-item($spacing: $default-column-spacing) {
  padding-bottom: $spacing;
  break-inside: avoid;
  // overflow: hidden;
}

//angled background

@mixin angled-background($pseudo: before, $angle: -8deg, $height: 50%) {

  @if $pseudo == 'before' or $pseudo == 'after' {

    position: relative;

    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      @include center(horizontal);
      background: inherit;
      content: '';
      display: block;
      height: $height;
      left: 50%;
      width: 101%;
      padding-bottom: 10%;
      position: absolute;
      z-index: 5;
      transform:translate(-50%, 0) skewY(-8deg);
    }

    @if $pseudo == 'before' {
      padding-top: 8%;

      &:before {
        transform:translate(-50%, 0) skewY($angle);
        bottom: 101%;
      }
    }
    @if $pseudo == 'after' {
      &:before {
        @include center(horizontal);
        transform:translate(-50%, 0) skewY($angle);
        top: 100%;
      }
    }
  }
}
@mixin button-transition($background-color:transparent, $text-color: $color-brand-red, $hover-background-color: $color-brand-red, $hover-text-color: $color-brand-white, $right-percentage: 110%) {
	color: $text-color;
	background-color: $background-color;
	backface-visibility: hidden;
	transform: translateZ(0);

	&:hover {
        color:$hover-text-color;

        &:after {
           transform: skewX(-25deg) translateX(0);
        }
    }

    &:after {
        @include transition(transform);

        content: '';
        display: block;
        position: absolute;
        right: -1em;
        top: 0;
        bottom: 0;
        left: -1em;
        background-color: $hover-background-color;
        transform: skewX(-25deg) translateX(-100%);
		    backface-visibility: hidden;
    }
}

@mixin button-transition-green($background-color:$color-brand-white, $text-color: $diversity-green, $hover-background-color: $diversity-green, $hover-text-color: $color-brand-white, $right-percentage: 110%) {
	color: $text-color;
	background-color: $background-color;
	backface-visibility: hidden;
	transform: translateZ(0);

	&:hover {
        color:$hover-text-color;

        &:after {
           transform: skewX(-25deg) translateX(0);
        }
    }

    &:after {
        @include transition(transform);

        content: '';
        display: block;
        position: absolute;
        right: -1em;
        top: 0;
        bottom: 0;
        left: -1em;
        background-color: $hover-background-color;
        transform: skewX(-25deg) translateX(-100%);
		    backface-visibility: hidden;
    }
}

@mixin button-transition-fuschia($background-color:transparent, $text-color: $color-brand-white, $hover-background-color: $color-brand-white, $hover-text-color: $diversity-fuschia, $right-percentage: 110%) {
	color: $text-color;
	background-color: $background-color;
	backface-visibility: hidden;
	transform: translateZ(0);

	&:hover {
        color:$hover-text-color;

        &:after {
           transform: skewX(-25deg) translateX(0);
        }
    }

    &:after {
        @include transition(transform);

        content: '';
        display: block;
        position: absolute;
        right: -1em;
        top: 0;
        bottom: 0;
        left: -1em;
        background-color: $hover-background-color;
        transform: skewX(-25deg) translateX(-100%);
		    backface-visibility: hidden;
    }
}

@mixin button-transition-orange($background-color:transparent, $text-color: $color-brand-white, $hover-background-color: $color-brand-white, $hover-text-color: $diversity-orange, $right-percentage: 110%) {
	color: $text-color;
	background-color: $background-color;
	backface-visibility: hidden;
	transform: translateZ(0);

	&:hover {
        color:$hover-text-color;

        &:after {
           transform: skewX(-25deg) translateX(0);
        }
    }

    &:after {
        @include transition(transform);

        content: '';
        display: block;
        position: absolute;
        right: -1em;
        top: 0;
        bottom: 0;
        left: -1em;
        background-color: $hover-background-color;
        transform: skewX(-25deg) translateX(-100%);
		    backface-visibility: hidden;
    }
}

@mixin button-transition-blue($background-color:transparent, $text-color: $color-brand-white, $hover-background-color: $color-brand-white, $hover-text-color: $diversity-blue, $right-percentage: 110%) {
  color: $text-color;
  background-color: $background-color;
  backface-visibility: hidden;
  transform: translateZ(0);

  &:hover {
        color:$hover-text-color;

        &:after {
           transform: skewX(-25deg) translateX(0);
        }
    }

    &:after {
        @include transition(transform);

        content: '';
        display: block;
        position: absolute;
        right: -1em;
        top: 0;
        bottom: 0;
        left: -1em;
        background-color: $hover-background-color;
        transform: skewX(-25deg) translateX(-100%);
        backface-visibility: hidden;
    }
}

@mixin button-transition-hero($background-color:$color-opaque-black, $text-color: $color-brand-white, $hover-background-color: $diversity-lime, $hover-text-color: $color-brand-black, $right-percentage: 110%) {
	color: $text-color;
	background-color: $background-color;
	backface-visibility: hidden;
	transform: translateZ(0);

	&:hover {
        color:$hover-text-color;

        &:after {
           transform: skewX(-25deg) translateX(0);
        }
    }

    &:after {
        @include transition(transform);

        content: '';
        display: block;
        position: absolute;
        right: -1em;
        top: 0;
        bottom: 0;
        left: -1em;
        background-color: $hover-background-color;
        transform: skewX(-25deg) translateX(-100%);
		    backface-visibility: hidden;
    }
}

@mixin button-transition-forecast-red($background-color:$color-brand-white, $text-color: $forecast-primary-red, $hover-background-color: $forecast-primary-red, $hover-text-color: $color-brand-white, $right-percentage: 110%) {
  color: $text-color;
  background-color: $background-color;
  backface-visibility: hidden;
  transform: translateZ(0);

  &:hover {
        color:$hover-text-color;

        &:after {
           transform: skewX(-25deg) translateX(0);
        }
    }

    &:after {
        @include transition(transform);

        content: '';
        display: block;
        position: absolute;
        right: -1em;
        top: 0;
        bottom: 0;
        left: -1em;
        background-color: $hover-background-color;
        transform: skewX(-25deg) translateX(-100%);
        backface-visibility: hidden;
    }
}

@mixin skew($x, $y) {
  -webkit-transform: skewX($x) skewY($y);
      -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
       -o-transform: skewX($x) skewY($y);
          transform: skewX($x) skewY($y);
}

@mixin max-width-xs() {
    @media screen and (max-width: $screen-xs) {
	  @content;
	}
}

@mixin max-width-sm() {
	@media screen and (max-width: $screen-sm) {
	  @content;
	}
}

@mixin max-width-between() {
	@media screen and (max-width: $screen-between) {
	  @content;
	}
}

@mixin max-width-md() {
    @media screen and (max-width: $screen-md) {
	  @content;
	}
}

@mixin max-width-lg() {
	@media screen and (max-width: $screen-lg) {
	  @content;
	}
}

@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}



// mixin for creating solid/gradient background edges
//https://www.viget.com/articles/angled-edges-with-css-masks-and-transforms

// @mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
//   // Possible values for $pseudo are: before, after, both
//   @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
//     position: relative;
//     z-index: 1;
//     $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

//     #{$selector} {
//       background: inherit;
//       content: '';
//       display: block;
//       height: 50%;
//       left: 0;
//       position: absolute;
//       right: 0;
//       z-index: -1;
//       -webkit-backface-visibility: hidden; // for Chrome Windows
//     }

//     @if $pseudo == 'before' {
//       #{$selector} {
//         top: 0;

//         @if $flip {
//           transform: skewY($angle * -1);
//           transform-origin: 0 0;
//         } @else {
//           transform: skewY($angle);
//           transform-origin: 100% 0;
//         }
//       }
//     }

//     @if $pseudo == 'after' {
//       #{$selector} {
//         bottom: 0;

//         @if $flip {
//           transform: skewY($angle);
//           transform-origin: 0 100%;
//         } @else {
//           transform: skewY($angle * -1);
//           transform-origin: 100%;
//         }
//       }
//     }

//     @if $pseudo == 'both' {
//       &:before {
//         top: 0;

//         @if $flip {
//           transform: skewY($angle * -1);
//           transform-origin: 0 0;
//         } @else {
//           transform: skewY($angle);
//           transform-origin: 100% 0;
//         }
//       }

//       &:after {
//         bottom: 0;

//         @if $flip {
//           transform: skewY($angle);
//           transform-origin: 0 0;
//         } @else {
//           transform: skewY($angle * -1);
//           transform-origin: 100%;
//         }
//       }
//     }
//   }
// }
