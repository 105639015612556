﻿/*========================================
=            Layout Variables            =
========================================*/
$site-padding: px-to-em($site-padding);
$max-width: px-to-em($site-max-width) + ($site-padding * 2);

$content-max-width: px-to-em($content-max-width) + ($site-padding * 2);
$content-large-max-width: px-to-em($content-large-max-width) + ($site-padding * 2);

$site-padding-mobile: px-to-em($site-padding-mobile-px);

//conversion to ems
$section-spacing: px-to-em($section-spacing-px);
$section-spacing--custom: px-to-em($section-spacing-px--custom);
$section-spacing-reduced: px-to-em($section-spacing-reduced-px);
$section-spacing-reduced-more: px-to-em($section-spacing-reduced-more-px);
$section-spacing-small: px-to-em($section-spacing-small-px);
$section-spacing-large: px-to-em($section-spacing-large-px);
$section-spacing-larger: px-to-em($section-spacing-larger-px);
$section-spacing-largest: px-to-em($section-spacing-largest-px);


$section-spacing-mobile: px-to-em($section-spacing-mobile-px);
$section-spacing-module-mobile: px-to-em($section-spacing-module-mobile-px);
$section-spacing-large-mobile: px-to-em($section-spacing-large-mobile-px);


//rewrite
$section-spacing-module: px-to-em($section-spacing-module-px);

$element-spacing:  px-to-em($element-spacing-px);
$element-spacing-small:   px-to-em($element-spacing-small-px);


@mixin page-wrapper {
	max-width: $max-width;
	padding-left: $site-padding;
	padding-right: $site-padding;
	margin-left: auto;
	margin-right: auto;

	@include screen($bp-tablet) {
		padding-left: $site-padding-mobile;
		padding-right: $site-padding-mobile;
	}
}

.page-wrapper {
	@include page-wrapper;
}

.page-wrapper-careers {
	@include page-wrapper;
	padding: 0 px-to-em(160px);
}

.page-wrapper--indented {
	padding-left: px-to-em(160px);

	margin-bottom: $section-spacing-module;
}

.page-wrapper--indented-nomargin {
	padding-left: px-to-em(160px);
}

@mixin content-wrapper {
	max-width: $content-max-width;
	padding-left: $site-padding;
	padding-right: $site-padding;
	margin-left: auto;
	margin-right: auto;

	@include screen($bp-tablet) {
		padding-left: $site-padding-mobile;
		padding-right: $site-padding-mobile;
	}
}
@mixin content-wrapper-scrollspy {
	max-width: $content-max-width;
	padding-left: $site-padding;
	padding-right: $site-padding;
	margin-left: auto;
	margin-right: auto;

	@include screen($bp-tablet) {
		padding-left: $scrollspy-nav-width - 1em;
		padding-right: $site-padding-mobile;
	}
	@include screen($bp-mobile) {
		padding-left: $site-padding-mobile;
		padding-right: $site-padding-mobile;
	}
}

.content-wrapper {
	@include content-wrapper;
}

@mixin content-large-wrapper {
	max-width: $content-large-max-width;
	padding-left: $site-padding * 1.5;
	padding-right: $site-padding * 1.5;
	margin-left: auto;
	margin-right: auto;

	@include screen($bp-tablet) {
		padding-left: $site-padding-mobile * 1.25;
		padding-right: $site-padding-mobile * 1.25;
	}
}
@mixin content-large-wrapper-scrollspy {
	max-width: $content-large-max-width;
	padding-left: $site-padding * 1.5;
	padding-right: $site-padding * 1.5;
	margin-left: auto;
	margin-right: auto;

	@include screen($bp-tablet) {
		padding-left: $scrollspy-nav-width - 1em;
		padding-right: $site-padding-mobile;
	}
	@include screen($bp-mobile) {
		padding-left: $site-padding-mobile;
		padding-right: $site-padding-mobile;
	}
}

//use this if you need top and bottom spacing, but not a wrapper
// .page-layout-spacing {
// 	padding-top:$section-spacing-large;
// 	margin-bottom: $section-spacing-largest;
// }

.layout-column-wrapper {
	@include clearfix;
}

.layout-column-main {
	float: left;
	width: $column-main-width;
	padding-right: 8%;
}

.layout-column-sidebar {
	width: $column-sidebar-width;
	float: right;
}

.layout-module {
	margin-bottom: $section-spacing-module;
}


.layout-column-wrapper--nomargin {
	.layout-module {
		margin-bottom: 0;
	}
}



@include screen($bp-desktop) {
	.page-wrapper--indented, .page-wrapper--indented-nomargin {
		padding-left: $site-padding;
	}
}

@include screen($bp-tablet) {
	.layout-module {
		margin-bottom: $section-spacing-module-mobile;
	}
	.layout-column-main {
		padding-right: 5%;
	}
}

@include screen($bp-mobile) {

	.page-wrapper--indented, .page-wrapper--indented-nomargin {
		margin-bottom: 0;
		padding-left: $site-padding-mobile;
	}
	
	.layout-column-main,
	.layout-column-sidebar {
		float: none;
		width: auto;
	}

	.layout-column-main {
		padding-right: 0;
	}
}
