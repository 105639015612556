///////////////////////////////////////////////////////////////////////////////
//  Fonts
///////////////////////////////////////////////////////////////////////////////
//
//  From Google Fonts
//  <link href="https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i|Open+Sans:300,400,700,800" rel="stylesheet">
//
//  Main Font - Open Sans
//  Weights:
//  Thin/300
//  Regular/400
//  bold: 700
//  Extrabold: 800
//
//  Body Copy -  Laro
//  Weight: 400, 400i, 700, 700i
///////////////////////////////////////////////////////////////////////////////

$font-family-primary:  $font-family-open-sans;
$font-family-copy:  $font-family-lora;


$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

//copy
$font-size-h1: 56px;
$font-size-h2: 52px;
$font-size-h3: 32px;
$font-size-h4: 24px;
$font-size-h5: 20px;

//copy-mobile
$font-size-h1-mobile: 40px;
$font-size-h2-mobile: 36px;
$font-size-h3-mobile: 24px;
$font-size-h4-mobile: 18px;
$font-size-h5-mobile: 16px;
$font-size-h6-mobile: 14px;

$font-line-height-1: 46px;
$font-line-height-2: 28px;
$font-line-height-3: 22px;


//module font sizes

$font-size-subheader: 30px;

$section-header-size: 24px;
$section-header-mobile-size: 20px;

$font-size-copy: 20px;
$font-size-copy-lead: 24px;

$font-size-copy-2column: 16px;
$font-size-copy-lead-2column: 20px;

$font-size-hero: 76px;
$font-size-hero-diversity: 72px;
$font-size-detail-diversity: 50px;
$font-size-subheading: 40px;
$font-size-buttons: 24px;

$font-size-hero-tablet: 45px;

$font-size-copy-mobile: 15px;
$font-size-copy-lead-mobile: 20px;

$font-size-hero-mobile: 40px;
$font-size-subheading-mobile: 30px;
$font-size-buttons-mobile: 15px;

//Careers
$font-size-quote: 28px;
$font-quote-section-number: 48px;

@mixin font-open-sans-light($font-size: null, $base-size: null){
    font-weight: $font-weight-light;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-open-sans-regular($font-size: null, $base-size: null){
    font-weight: $font-weight-regular;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-open-sans-semibold($font-size: null, $base-size: null){
    font-weight: $font-weight-semibold;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-open-sans-bold($font-size: null, $base-size: null){
    font-weight: $font-weight-bold;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-open-sans-extra-bold($font-size: null, $base-size: null){
    font-weight: $font-weight-extra-bold;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-copy-lora($font-size: null, $base-size: null){
    font-weight: $font-weight-light;
    font-family: $font-family-copy;
    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-size-buttons () {
    //@include font-univers-condensed-bold($font-size-buttons);

    line-height: 1.1;

    @include screen($bp-mobile) {
        //font-size: px-to-em($font-size-buttons-mobile);
    }
}

@mixin font-size-copy-text () {
    font-family: $font-family-copy;
    font-size: px-to-em($font-size-copy);
    line-height: $base-line-height;

    @include screen($bp-tablet) {
        font-size: px-to-em($font-size-copy-mobile);
    }
}

@mixin font-size-copy-lead () {
    font-family: $font-family-copy;
    font-size: px-to-em($font-size-copy-lead);
    line-height: $base-line-height;

    @include screen($bp-tablet) {
        font-size: px-to-em($font-size-copy-lead-mobile);
    }
}

@mixin font-size-forecast-copy-text-2column () {
    @include font-open-sans-regular($font-size-copy-2column);
    font-family: $font-family-primary;
    line-height: $base-line-height;

    @include screen($bp-tablet) {
        font-size: px-to-em($font-size-copy-mobile);
    }
}

@mixin font-size-forecast-copy-lead-2column () {
    font-family: $font-family-primary;
    font-size: px-to-em($font-size-copy-lead-2column);
    line-height: $base-line-height;

    @include screen($bp-tablet) {
        font-size: px-to-em($font-size-copy-lead-mobile);
    }
}


body {
    font-size: 100%;
    font:400 100% $font-family-primary;
    line-height: 1.2;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $color-text-copy;
}

h1,h2,h3,h4,h5,h6 {
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    margin: 0;
}

h1 {
    font-size: px-to-em($font-size-h1);
    line-height: 1.1;
}

h2 {
    font-size: px-to-em($font-size-h2);
    line-height: 1.1;
}

h3 {
    font-size: px-to-em($font-size-h3);
}

h4 {
    font-size: px-to-em($font-size-h4);
}

h5 {
    font-size: px-to-em($font-size-h5);
}
